<template>
  <div class="WalletDetails">
    <div class="header">
      <router-link :to="{ name: 'wallets' }" class="WalletDetails__go-back">
        <i class="icon-left"></i>
        Back to Wallets
      </router-link>
      <div class="header-title">User wallet</div>
    </div>
    <div class="panel">
      <div class="Grid-row">
        <div class="Grid-column-12">
          <table class="WalletDetails__list">
            <thead>
              <tr class="WalletDetails__list-header">
                <th class="WalletDetails__list-cell">Type</th>
                <th class="WalletDetails__list-cell">Amount</th>
                <th class="WalletDetails__list-cell">Info</th>
              </tr>
            </thead>
            <tr
              v-for="(transaction, index) in transactions"
              :key="index"
              class="WalletDetails__list-row"
            >
              <td
                class="WalletDetails__list-cell WalletDetails__transaction-type"
              >
                <i :class="types[transaction.type].icon"></i>
                {{ types[transaction.type].label }}
              </td>
              <td class="WalletDetails__list-cell">
                &#8358; {{ (transaction.amount / 100) | number }}
              </td>
              <td class="WalletDetails__list-cell">
                <div
                  v-if="transaction.type === 'walletCharge'"
                  class="WalletDetails__transaction-info"
                >
                  <div class="WalletDetails__transaction-info-row">
                    <span class="WalletDetails__transaction-info-label"
                      >Card name:</span
                    >
                    {{ transaction.cardName }}
                  </div>
                  <div class="WalletDetails__transaction-info-row">
                    <span class="WalletDetails__transaction-info-label"
                      >Last 4 card digits:</span
                    >
                    {{ transaction.cardLast4 }}
                  </div>
                  <div class="WalletDetails__transaction-info-row">
                    <span class="WalletDetails__transaction-info-label"
                      >Created at:</span
                    >
                    {{
                      transaction.createdAtMillis | moment('MMM DD, YYYY H:mm')
                    }}
                  </div>
                </div>
                <div
                  v-else-if="transaction.type === 'walletWithdrawal'"
                  class="WalletDetails__transaction-info"
                >
                  <div class="WalletDetails__transaction-info-row">
                    <span class="WalletDetails__transaction-info-label"
                      >Reipient bank account:</span
                    >
                    {{ transaction.reipientBankAccount }}
                  </div>
                  <div class="WalletDetails__transaction-info-row">
                    <span class="WalletDetails__transaction-info-label"
                      >Created at:</span
                    >
                    {{
                      transaction.createdAtMillis | moment('MMM DD, YYYY H:mm')
                    }}
                  </div>
                </div>
                <div v-else class="WalletDetails__transaction-info">
                  <div class="WalletDetails__transaction-info-row">
                    <span class="WalletDetails__transaction-info-label"
                      >Reipient account:</span
                    >
                    {{ transaction.recipentAccount }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <infinite-loading
                  ref="infiniteLoading"
                  @infinite="getWalletTransactions"
                >
                  <div slot="no-more"></div>
                  <div slot="no-results">
                    There is no transactions for this wallet
                  </div>
                </infinite-loading>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { number } from '@/utils/filters';

const { mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers('wallets');

export default {
  name: 'WalletDetails',
  components: {
    InfiniteLoading,
  },
  filters: { number },
  data() {
    return {
      types: {
        walletCharge: { icon: 'icon-level-up', label: 'Charge' },
        walletWithdrawal: { icon: 'icon-level-down', label: 'Withdrawal' },
        moneyTransfer: { icon: 'icon-exchange', label: 'Money Transfer' },
      },
    };
  },
  computed: {
    ...mapGetters(['walletTransactions', 'walletTransactionsNextToken']),
    transactions() {
      return this.walletTransactions.map((t) => ({
        type: Object.keys(t)[0],
        ...t[Object.keys(t)[0]],
      }));
    },
  },
  beforeDestroy() {
    this.clearWalletTransactions();
  },
  methods: {
    ...mapActions(['fetchWalletTransactions']),
    ...mapMutations(['clearWalletTransactions']),
    async getWalletTransactions(st) {
      const { response } = await this.fetchWalletTransactions(
        this.$route.params.id
      );
      if (response && response.transactions && response.transactions.length)
        st.loaded();
      if (!this.walletTransactionsNextToken) st.complete();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/wallets/WalletDetails.scss';
</style>
